<template>
  <div class="app-container">
    <CrudTable ref="table" entity="User" :columns="columns" :requires-domain-id="true" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
import crudIndexMixin from '@/mixins/crud-index';
export default {
  components: {
    CrudTable
  },
  mixins: [crudIndexMixin],
  data() {
    return {
      columns: [{ field: 'code', width: 150 }, 'name']
    };
  }
};
</script>

<style scoped></style>
